import Rails from "@rails/ujs";

let updateNUModalContent = function(dataset, message, dialogMessage, dialogTitle, cancelButton,
                                    okButton, deleteButton, errorIcon, warningIcon, successIcon) {
  if (message) {
    dialogMessage.innerHTML = message;
  }

  if (dataset) {
    if (dataset.confirmMessage) {
      dialogMessage.innerHTML = dataset.confirmMessage;
    }

    if (dataset.confirmTitle) {
      dialogTitle.innerHTML = dataset.confirmTitle;
    }

    if (dataset.confirmCancel) {
      cancelButton.innerHTML = dataset.confirmCancel;
    }

    if (dataset.confirmAllow) {
      okButton.innerHTML = dataset.confirmAllow;

      if (deleteButton) {
        deleteButton.innerHTML = dataset.confirmAllow;
      }
    }

    // Admin confirmation modal specific code
    if (dataset.confirmError) {
      okButton.classList.add('hidden');
      deleteButton.classList.remove('hidden');
      warningIcon.classList.add('hidden');
      errorIcon.classList.remove('hidden');
    }

    // Admin confirmation modal specific code
    if (dataset.confirmSuccess) {
      warningIcon.classList.add('hidden');
      successIcon.classList.remove('hidden');
    }
  }
}

Rails.confirm = function(
  message, element, dataset = null, options = {}
) {
  let backgroundBody = document.querySelector('#js-body');
  let dialog = document.querySelector('#confirmation-modal');
  let dialogMessage = dialog.querySelector('#modal-content');
  let dialogTitle = dialog.querySelector('#modal-title');
  let okButton = dialog.querySelector('.modal-ok-button');
  let cancelButton = dialog.querySelector('.modal-cancel-button');

  let defaultMessage = dialogMessage.innerHTML;
  let defaultTitle = dialogTitle.innerHTML;
  let defaultOkText = okButton.innerHTML;
  let defaultCancelText = cancelButton.innerHTML;

  // Admin confirmation modal specific variables
  let errorIcon = dialog.querySelector('#modal-icon-error');
  let warningIcon = dialog.querySelector('#modal-icon-warning');
  let successIcon = dialog.querySelector('#modal-icon-success');
  let deleteButton = dialog.querySelector('.modal-delete-button');
  let defaultDeleteText;

  let confirmCallback = options.confirmCallback || null;
  let cancelCallback = options.cancelCallback || null;

  if (deleteButton) {
    defaultDeleteText = deleteButton.innerHTML;
  }

  const data = (dataset || element.dataset);
  updateNUModalContent(data, message, dialogMessage, dialogTitle, cancelButton, okButton,
    deleteButton, errorIcon, warningIcon, successIcon);

  dialog.classList.remove('hidden');
  backgroundBody.classList.add('overflow-hidden');

  dialog.addEventListener('keydown', (event) => {
    if (event.key === 'Tab') {
      if (document.activeElement === cancelButton && event.shiftKey) {
        okButton.focus();
        event.preventDefault();
      } else if ((document.activeElement === okButton || document.activeElement === deleteButton) && !event.shiftKey) {
        cancelButton.focus();
        event.preventDefault();
      }
    }
  });

  const confirmAction = () => {
    dialog.classList.add('hidden');
    backgroundBody.classList.remove('overflow-hidden');

    dialogMessage.innerHTML = defaultMessage;
    dialogTitle.innerHTML = defaultTitle;
    okButton.innerHTML = defaultOkText;
    cancelButton.innerHTML = defaultCancelText;

    if (deleteButton) {
      deleteButton.innerHTML = defaultDeleteText;
    }

    errorIcon?.classList.add('hidden');
    successIcon?.classList.add('hidden');
    deleteButton?.classList.add('hidden');

    let old = Rails.confirm
    Rails.confirm = function() { return true }
    element.click();
    Rails.confirm = old

    cancelButton.removeEventListener('click', cancelAction);

    if (deleteButton) {
      okButton.classList.remove('hidden');
      warningIcon.classList.remove('hidden');
      errorIcon.classList.add('hidden');
    }

    if (confirmCallback) {
      confirmCallback();
    }
  };

  const cancelAction = () => {
    dialog.classList.add('hidden');
    backgroundBody.classList.remove('overflow-hidden');

    dialogMessage.innerHTML = defaultMessage;
    dialogTitle.innerHTML = defaultTitle;
    okButton.innerHTML = defaultOkText;
    cancelButton.innerHTML = defaultCancelText;

    errorIcon?.classList.add('hidden');
    successIcon?.classList.add('hidden');
    deleteButton?.classList.add('hidden');

    okButton.removeEventListener('click', confirmAction);

    if (deleteButton) {
      deleteButton.innerHTML = defaultDeleteText;
      deleteButton.removeEventListener('click', confirmAction);

      okButton.classList.remove('hidden');
      warningIcon.classList.remove('hidden');
      errorIcon.classList.add('hidden');
    }

    if (cancelCallback) {
      cancelCallback();
    }
  };

  okButton.addEventListener('click', confirmAction, {once: true});
  cancelButton.addEventListener('click', cancelAction, {once: true});

  cancelButton.focus();

  if (deleteButton) {
    deleteButton.addEventListener('click', confirmAction, {once: true});
  }

  return false;
}
