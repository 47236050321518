import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['dueDateItem', 'taskLockItem', 'goalItem', 'taskItem', 'taskList'];
  toggleOptionData(event) {

    this.resetOptionData();

    let value = event.currentTarget.dataset.value;

    if (value === 'due_date') {
      if (this.hasDueDateItemTarget) {
        this.dueDateItemTargets.forEach((item) => {
          item.classList.remove('hidden');
          this._openClosestTaskList(item);
        });
      }
    } else if (value === 'task_lock') {
      if (this.hasTaskLockItemTarget) {
        this.taskLockItemTargets.forEach((item) => {
          item.classList.remove('hidden');
          this._openClosestTaskList(item);
        });
      }
    } else if (value === 'goal') {
      if (this.hasGoalItemTarget) {
        this.goalItemTargets.forEach((item) => {
          item.classList.remove('hidden');
          this._openClosestTaskList(item);
        });
      }
    }
  }

  toggleAllOptionData() {
    this.dueDateItemTargets.forEach((item) => {
      item.classList.remove('hidden');
      this._openClosestTaskList(item);
    });

    this.taskLockItemTargets.forEach((item) => {
      item.classList.remove('hidden');
      this._openClosestTaskList(item);
    });

    this.goalItemTargets.forEach((item) => {
      item.classList.remove('hidden');
      this._openClosestTaskList(item);
    });

    this.taskItemTargets.forEach((item) => {
      item.classList.remove('hidden');
      this._openClosestTaskList(item);
    });
  }

  resetOptionData() {
    this._closeAllTaskLists();

    if (this.hasDueDateItemTarget) {
      this.dueDateItemTargets.forEach((item) => {
        item.classList.add('hidden');
      });
    }

    if (this.hasTaskLockItemTarget) {
      this.taskLockItemTargets.forEach((item) => {
        item.classList.add('hidden');
      });
    }

    if (this.hasGoalItemTarget) {
      this.goalItemTargets.forEach((item) => {
        item.classList.add('hidden');
      });
    }
  }

  _openClosestTaskList(item) {
    let closestTaskList = item.closest('.js-task-list-top').querySelector('.js-task-list');

    if (!closestTaskList.classList.contains('open')) {
      closestTaskList.querySelector('.js-task-list-icon').click();
    }

    let container = item.closest('.task-list-item-container');

    if (container) {
      container.classList.add('pt-2');
      container.classList.add('pb-4');
    }
  }

  _closeAllTaskLists() {
    this.taskListTargets.forEach((list) => {
      if (list.classList.contains('open')) {
        list.querySelector('.js-task-list-icon').click();

      }
    });

    let container = document.querySelectorAll('.task-list-item-container');

    container.forEach((item) => {
      item.classList.remove('pt-2');
      item.classList.remove('pb-4');
    });
  }
}
