import { Controller } from "stimulus";
import { ajax } from '@rails/ujs';

export default class extends Controller {
  static targets = ['container', 'content'];

  static values = {
    programId: String,
    contentPageId: String
  }

  connect() {
    document.addEventListener('promote:open-editor', this._openEditor.bind(this));
    document.addEventListener('promote:close-editor', this._closeEditor.bind(this));

    if (this.contentPageIdValue) {
      this._updateUrlOnOpen();
      this._loadBuilder();
    }
  }

  disconnect() {
    document.removeEventListener('promote:open-editor', this._openEditor.bind(this));
    document.removeEventListener('promote:close-editor', this._closeEditor.bind(this));
  }

  async verifyFormsAndClose() {
    try {
      await this.verify();

      this.close();
    } catch (e) {
      // no-op
    }
  }

  async verify() {
    const unloadChecker = this.application
      .getControllerForElementAndIdentifier(document.body, "unload-checker");

    return await unloadChecker.verify();
  }

  followUrl(event) {
    event.preventDefault();

    let { url } = event.currentTarget.dataset;

    this.verify().then(() => {
      try {
        ajax({
          url,
          type: 'get',
          dataType: 'script'
        });
      } catch (_e) {
        return;
      }
    }).catch(_e => {});
  }

  close() {
    this._closeEditor();

    this._updateUrlOnClose();
    this._deselectAllCards();
    this._highlightModule();

    this.contentPageIdValue = null;
  }

  _openEditor() {
    if (this._sidebarCollapsed()) {
      this.containerTarget.classList.add('sidebar-closed');
    }

    this.containerTarget.classList.remove('closed');
  }

  _closeEditor() {
    this.containerTarget.classList.add('closed');
    this.contentTarget.innerHTML = '';
  }

  _deselectAllCards() {
    let selectedCards = document.querySelectorAll('.js-program-module .selected');

    selectedCards.forEach((card) => {
      card.classList.remove('selected');
    });
  }

  _highlightModule() {
    if (!this.hasContentPageIdValue) {
      return;
    }

    const currentContentPage = document.getElementById(`js-content-page-overview-card-${this.contentPageIdValue}`);

    const currentModule = currentContentPage.closest('[class*=js-program-module-card-]');

    if (currentModule) {
      document.dispatchEvent(new CustomEvent('promote:highlight', {
        detail: { element: currentModule }
      }));
    }
  }

  _updateUrlOnClose() {
    let url = `/admin/programs/${this.programIdValue}/program_modules`;

    window.history.pushState({}, "", url.split('?')[0]);
  }

  _updateUrlOnOpen() {
    window.history.pushState({}, "", this._openUrl());
  }

  _loadBuilder() {
    if (this.ajaxRequest) {
      this.ajaxRequest.abort(); // Abort previous ajax request.
    }

    ajax({
      url: this._openUrl(),
      type: "GET",
      dataType: "script",
      beforeSend: (xhr) => {
        this.ajaxRequest = xhr;
        return true;
      },
      success: () => {
        this._setContainerWidth();
      }
    })
  }

  _openUrl() {
    return `/admin/programs/${this.programIdValue}/editor/content_pages/${this.contentPageIdValue}/edit`;
  }

  _setContainerWidth() {
    const sidebarCollapsed = localStorage.getItem(`admin_sidebar_collapsed`) === 'true';

    if (sidebarCollapsed) {
      document.getElementById('program-builder').classList.add('sidebar-closed');
    }
  }

  _sidebarCollapsed() {
    return localStorage.getItem(`admin_sidebar_collapsed`) === 'true';
  }
}
