import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['moveButton']

  static values = {
    forModule: Boolean
  }

  selectMoveMenuItem(event) {
    event.target.closest('.move-menu-content')
      .querySelectorAll('.move-menu-item').forEach((item) => {
      item.classList.remove('selected');
    })

    event.target.classList.add('selected');

    this.moveButtonTarget.querySelector('button').disabled = false;

    let baseUrl = this.moveButtonTarget.href.split('?')[0];

    if (this.forModuleValue) {
      this.moveButtonTarget.href = `${baseUrl}?after_id=${event.target.dataset.value}`;
    } else {
      this.moveButtonTarget.href = `${baseUrl}?module_id=${event.target.dataset.value}`;
    }
  }
}
