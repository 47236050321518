import { Controller } from "stimulus";
import { ajax } from "@rails/ujs";
import PromoteHelpers from "../ng_admin/deprecated_promote_helpers";

export default class extends Controller {
  static targets = ["menu", "menuItem", "icon", "label", "promptOption", "component",
    'hiddenField', "menuFilter", "clearFilterIcon"]

  static values = {
    direction: String,
    prompt: String,
    placement: String
  }
  connect() {
    let preSelectedItem = this._preSelectedItem();

    if (preSelectedItem) {
      this._showPromptOption();
      this._replaceSelectedOption(preSelectedItem);
    }

    this.resizeMenu();
  }

  toggleMenu() {
    const openMenu = document.querySelector('[data-inputs-dropdown-target="menu"].open');

    if (openMenu && openMenu.id !== this.menuTarget.id) {
      openMenu.classList.toggle('invisible');
      openMenu.classList.toggle('open');

      const dropdown = openMenu.closest('[data-inputs-dropdown-target="component"]');
      dropdown.querySelector('[data-inputs-dropdown-target="icon"]').classList.toggle('rotate-180');
      dropdown.classList.toggle('hover:border-purple-300');
    }

    this.menuTarget.classList.toggle('open');

    const dropdown = event.target.closest('[data-inputs-dropdown-target="component"]');
    const coords = dropdown.getBoundingClientRect();

    this.menuTarget.style.left = coords.left + 'px';

    if (this.directionValue === 'up') {
      this.menuTarget.style.top = `${ coords.top - this.menuTarget.offsetHeight - 1.5 }px`;
    } else {
      this.menuTarget.style.top = `${ coords.bottom + 1.5 }px`;
    }

    this.menuTarget.classList.toggle('invisible');
    this.iconTarget.classList.toggle('rotate-180');
    this.componentTarget.classList.toggle('hover:border-purple-300');
  }

  toggleOption(event) {
    let target = event.currentTarget;

    if (this.hasMenuFilterTarget) {
      this.filterReset();
    }
    this._deselectItems();
    this._selectItem(target);
    this._setHiddenFieldValue(target);
    this._showPromptOption();
    this._replaceSelectedOption(target);
    this.toggleMenu();
    this._triggerChangeEvent();
  }

  resetDropdown(event) {
    this._replaceSelectedOption(event.currentTarget, false);
    this._hidePromptOption();
    this._resetHiddenFieldValue();
    this._deselectItems();
    this.toggleMenu();
    this._triggerChangeEvent();
  }

  loadData(event) {
    if (this.ajaxRequest) {
      this.ajaxRequest.abort(); // Abort previous ajax request.
    }

    ajax({
      url: event.params.url,
      type: event.params.method || "GET",
      dataType: "script",
      beforeSend: (xhr) => {
        this.ajaxRequest = xhr;
        return true;
      }
    })
  }

  hideMenu(event) {
    if (this.element.contains(event.target) === false &&
      !this.menuTarget.classList.contains('hidden')) {
      this.toggleMenu();
    }
  }

  resizeMenu() {
    this.menuTarget.style.width = `${  this.componentTarget.offsetWidth }px`;
    this.menuTarget.style.left = `${ this.componentTarget.getBoundingClientRect().left }px`;
  }

  scrollMenu() {
    const openMenu = document.querySelector('[data-inputs-dropdown-target="menu"].open');

    if (!openMenu) {
      return;
    }

    const dropdown = openMenu.closest('[data-inputs-dropdown-target="component"]');
    const coords = dropdown.getBoundingClientRect();

    if (dropdown.dataset.inputsDropdownDirectionValue === 'up') {
      openMenu.style.top = `${ coords.top - openMenu.offsetHeight - 1.5 }px`;
    } else {
      openMenu.style.top = `${ coords.bottom + 1.5 }px`;
    }
  }

  filterOnInput(event) {
    const filterValue = event.currentTarget.value.toLowerCase();

    this._toggleClearFilterIcon();

    this.menuItemTargets.forEach((item) => {
      const itemText = item.textContent.toLowerCase();

      if (itemText.includes(filterValue)) {
        this._hidePromptOption();
        item.classList.remove('hidden');
      } else {
        this._hidePromptOption();
        item.classList.add('hidden');
      }
    });

    this.scrollMenu();
  }

  filterReset() {
    if (this._hasSelectedItems()) {
      this._showPromptOption();
    }
    this.menuFilterTarget.value = '';
    this._showItems(this.menuItemTargets);
    this._toggleClearFilterIcon();
    this.scrollMenu();
  }

  _toggleClearFilterIcon() {
    if (this.menuFilterTarget.value === '') {
      this.clearFilterIconTarget.classList.add('hidden');
    } else if (this.menuFilterTarget.value !== '') {
      this.clearFilterIconTarget.classList.remove('hidden');
    }
  }

  _showItems(items) {
    items.forEach((item) => {
      item.classList.remove('hidden');
    });
  }

  _hidePromptOption() {
    if (this.hasPromptOptionTarget) {
      this.promptOptionTarget.classList.add("hidden");
    }
  }

  _showPromptOption() {
    if (this.hasPromptOptionTarget) {
      this.promptOptionTarget.classList.remove("hidden");
    }
  }

  _hasSelectedItems() {
    return this.menuItemTargets.some((item) => item.classList.contains("js-selected"));
  }

  _deselectItems() {
    this.menuItemTargets.forEach((item) => {
      item.querySelector(".js-menu-option-check").classList.add("invisible");
      item.classList.remove("js-selected");
    });
  }

  _preSelectedItem() {
    let selectedItem;

    this.menuItemTargets.forEach((item) => {
      if (item.classList.contains("js-selected")) {
        selectedItem = item;
      }
    });

    return selectedItem;
  }

  _selectItem(target) {
    target.querySelector(".js-menu-option-check").classList.remove("invisible");
    target.classList.add("js-selected");
  }

  _setHiddenFieldValue(target) {
    this.hiddenFieldTarget.value = target.dataset.value;
  }

  _resetHiddenFieldValue() {
    this.hiddenFieldTarget.value = '';
  }

  _replaceSelectedOption(target, display_prompt = true) {
    if (this.promptValue && display_prompt) {
      const targetText = target.querySelector(".js-menu-option-text");
      targetText.classList.add("truncate")

      this.labelTarget.innerHTML = `${this.promptValue} ${targetText.innerHTML}`;
    } else {
      this.labelTarget.innerHTML = target.innerHTML;

      const labelMenuOptionText = this.labelTarget.querySelector(".js-menu-option-text");

      if (labelMenuOptionText) {
        labelMenuOptionText.classList.add("truncate");
      }
    }

    const menuOptionCheck = this.labelTarget.querySelector(".js-menu-option-check");

    if (display_prompt && menuOptionCheck) {
      menuOptionCheck.classList.add("invisible");
    }

    if (this.labelTarget.querySelector(".js-menu-option-text")) {
      this.labelTarget.querySelector(".js-menu-option-text").classList.remove("ml-5");
    }
  }

  _triggerChangeEvent() {
    PromoteHelpers.dispatchEvent({
      target: this.hiddenFieldTarget,
      type: 'change'
    });
  }
}
