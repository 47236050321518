import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['submenu', 'menuItem', 'icon']
  toggle() {
    if (this._sidebarIsCollapsed() && !this._viewportIsMobile()) {
      this._displayMenuText();
      this._toggleSubmenuOnCollapse();
    } else {
      this.submenuTarget.classList.toggle('hidden');
      this.iconTarget.classList.toggle('rotate-180');
    }
  }

  findController(name) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === name;
    });
  }

  _sidebarIsCollapsed() {
    return localStorage.getItem(`admin_sidebar_collapsed`) === 'true';
  }

  _viewportIsMobile() {
    return window.innerWidth < 640;
  }

  _sideBarWidth() {
    return document.querySelector('.js-sidebar-menu').offsetWidth;
  }

  _displayMenuText() {
    this.menuItemTargets.forEach((item, index)=> {
      if (index !== 0) {
        item.classList.toggle('sm:hidden');
      }
    });
  }

  _toggleSubmenuOnCollapse() {
    ['hidden', 'flex', 'absolute', 'pt-3', 'ml-5', 'bg-purple-700', 'pr-2',
      'py-1', 'rounded-r-md'].forEach(klass => {
      this.submenuTarget.classList.toggle(klass);
    })

    this.submenuTarget.style = `left: ${this._sideBarWidth()}px;`;
  }
}
