import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    userId: Number
  }

  static targets = ['sidebar', 'iconExpanded', 'iconCollapsed', 'mobileIcon', 'logo',
    'menuItem', 'topNav', 'content', 'userMenu']

  connect() {
    this._setInitialCollapsedValue();

    if (this._sidebarIsCollapsed()) {
      this._toggleContent();
    }
  }

  toggle() {
    this._toggleContent();
    this._updateCollapsedValue();
    this._toggleUserMenu();
  }

  toggleOnMobile() {
    this.contentTarget.classList.toggle('hidden');
    this.mobileIconTargets.forEach(icon => {
      icon.classList.toggle('hidden');
    })
  }

  _toggleContent() {
    this._toggleSidebarClasses();
    this._toggleTopNavClasses();
    this._toggleLogoClasses();
    this._toggleIconClasses();
    this._hideItemText();
    this._toggleAvatarPosition();
  }

  _toggleAvatarPosition() {
    this.userMenuTarget.classList.toggle('sm:items-center');
  }

  _setInitialCollapsedValue() {
    let itemString = `admin_sidebar_collapsed`;

    if (localStorage.getItem(itemString) === null) {
      localStorage.setItem(itemString, false);
      window.dispatchEvent( new Event('storage') )
    }
  }

  _updateCollapsedValue() {
    let itemString = `admin_sidebar_collapsed`;

    localStorage.setItem(itemString, !JSON.parse(localStorage.getItem(itemString)));
    window.dispatchEvent( new Event('storage') )
  }

  _sidebarIsCollapsed() {
    return localStorage.getItem(`admin_sidebar_collapsed`) === 'true';
  }

  _toggleSidebarClasses() {
    ['sm:w-300', 'w-full', 'sm:pt-10'].forEach(style=> {
      this.sidebarTarget.classList.toggle(style);
    })
  }

  _toggleTopNavClasses() {
    ['sm:justify-between', 'sm:justify-center'].forEach(style=> {
      this.topNavTarget.classList.toggle(style);
    })
  }

  _toggleLogoClasses() {
    this.logoTarget.classList.toggle('sm:hidden');
  }

  _toggleIconClasses() {
    if (this.iconExpandedTarget.classList.contains('sm:flex')) {
      this._hideIcon(this.iconExpandedTarget);
      this._showIcon(this.iconCollapsedTarget);
    } else {
      this._hideIcon(this.iconCollapsedTarget);
      this._showIcon(this.iconExpandedTarget);
    }
  }

  _hideIcon(iconTarget) {
    iconTarget.classList.remove('sm:flex');
    iconTarget.classList.add('sm:hidden');
  }

  _showIcon(iconTarget) {
    iconTarget.classList.remove('sm:hidden');
    iconTarget.classList.add('sm:flex');
  }

  _hideItemText() {
    this.menuItemTargets.forEach(item => {
      item.classList.toggle('sm:hidden');
    })
  }

  _toggleUserMenu() {
    let userMenuController = this.application.controllers.find(controller => {
      return controller.context.identifier === 'sidebar-user-menu';
    });

    userMenuController.toggleUserMenuOnCollapse();
  }
}
