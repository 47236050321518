import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "message", "defaultMessage", "previewMessage", "customMessage" ];

  showError() {
    this._showErrorMessage();
  }

  showPreviewError(e) {
    e.preventDefault();
    e.stopPropagation();

    this._setPreviewErrorMessage();
    this._showErrorMessage();
  }

  showCustomError(message) {
    this.customMessageTarget.innerHTML = message;
    this._setCustomErrorMessage();
    this._showErrorMessage();
  }

  hideError() {
    this._setDefaultErrorMessage();
    this._hideErrorMessage();
  }

  _setPreviewErrorMessage() {
    this.defaultMessageTarget.classList.add('hidden');
    this.customMessageTarget.classList.add('hidden');
    this.previewMessageTarget.classList.remove('hidden');
  }

  _setDefaultErrorMessage() {
    this.previewMessageTarget.classList.add('hidden');
    this.customMessageTarget.classList.add('hidden');
    this.defaultMessageTarget.classList.remove('hidden');
  }

  _setCustomErrorMessage() {
    this.defaultMessageTarget.classList.add('hidden');
    this.previewMessageTarget.classList.add('hidden');
    this.customMessageTarget.classList.remove('hidden');
  }

  _hideErrorMessage() {
    this.messageTarget.classList.add('hidden');
  }

  _showErrorMessage() {
    this.messageTarget.classList.remove('hidden');
    this._setTimeout();
  }

  _setTimeout() {
    window.setTimeout(() => {
      this.hideError();
    }, 20000);
  }
}
