import { Controller } from "stimulus";

export default class extends Controller {
  updateUrl(event) {
    let url;

    if (event.currentTarget.nodeName === 'A') {
      url = event.currentTarget.href;
    } else {
      url = event.currentTarget.closest('a').href;
    }

    window.history.pushState({}, "", url.split('?')[0]);
  }
}
