import { Controller } from "stimulus";
export default class extends Controller {
  static targets = [ "button", "input" ]

  static values = {
    default: String
  }
  connect() {
    this.inputTarget.addEventListener('change', this._onChange.bind(this));
  }

  displayDatePicker() {
    this.inputTarget.showPicker();
  }

  _onChange(event) {
    this.buttonTarget.querySelector('.admin-button-text').innerHTML = this._formattedDate(event.target.value);
    this.inputTarget.value = event.target.value;
  }

  _formattedDate(value) {
    if (value === '') {
      return this.defaultValue;
    }

    let date = new Date(value);
    let utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);

    return `${utcDate.getDate()} ${utcDate.toLocaleString('en-GB', { month: 'short', year: 'numeric' })}`;
  }
}
