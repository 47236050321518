import { Controller } from "stimulus";
import { ajax } from "@rails/ujs";

let debounce = require('lodash/debounce');

export default class extends Controller {
  static targets = ['filter', 'input', 'checkbox', 'closeIcon', 'toggle'];

  initialize() {
    this.params = {};
    this.url;

    this.filterTargets.forEach(filter => {
      if (filter.classList.contains('active')) {
        this.params[filter.dataset.filterName] = filter.dataset.filterValue;
      }
    });

    this.filterOnInput = debounce(this.filterOnInput, 300).bind(this)
  }

  filterOnInput(event) {
    let target = event.target;

    target.dataset.filterValue = target.value;

    this.url = target.dataset.url;

    this.toggleCloseIcon();
    this._updateFilters(target);

    this._loadData();
  }

  filterOnClick(event) {
    let target = event.target;

    if (target.nodeName !== 'LI') {
      target = target.closest('li');
    }

    this.url = target.dataset.url;

    this._clearInputSearch();
    this._clearOverdueCheckbox();
    this._updateFilters(target);

    let contentId = target.dataset.contentId;
    let contentEl = document.getElementById(contentId);

    if (
      contentEl.dataset.contentForceReload === 'true' ||
      contentEl.dataset.contentLoaded !== 'true'
    ) {
      this._loadData(this._toggleOverdueCheckbox.bind(this, target));
    }
  }

  filterOnChange(event) {
    let target = event.target;

    target.dataset.filterValue = target.value;

    this.url = target.dataset.url;

    const filterName = target.dataset.filterName;

    if (filterName) {
      this.params[filterName] = `${target.checked}`;
      target.dataset.filterValue = `${target.checked}`;
    }

    this._loadData();
  }

  filterOnSelect(event) {
    let target = event.target;
    this.url = target.dataset.url;

    this._updateFilters(target);

    this._loadData();
  }

  resetOnClick() {
    this._clearInputSearch();
    this._loadData();
    this.toggleCloseIcon();
  }

  _updateFilters(target) {
    const filterName = target.dataset.filterName;
    if (filterName) {
      this.params[filterName] = target.dataset.filterValue;
    }
  }

  _clearInputSearch() {
    if (this.hasInputTarget && this.inputTarget !== '') {
      // Clear input field and dataset
      this.inputTarget.value = '';
      this.inputTarget.dataset.filterValue = '';

      // Remove filter value from params
      this.params[this.inputTarget.dataset.filterName] = '';
    }
  }

  _clearOverdueCheckbox() {
    if (this.hasCheckboxTarget && this.checkboxTarget.dataset.filterValue === 'true') {
      // Clear checkbox and dataset
      this.checkboxTarget.checked = false;
      this.checkboxTarget.dataset.filterValue = 'false';

      // Remove checkbox value from params
      this.params[this.checkboxTarget.dataset.filterName] = '';
    }
  }

  _toggleOverdueCheckbox(target) {
    if (this.hasCheckboxTarget) {

      let contentId = target.dataset.contentId;

      let overdueItems = document.querySelectorAll(`#${contentId} .js-is-overdue`)

      if (overdueItems.length) {
        this.checkboxTarget.parentElement.parentElement.classList.remove('hidden');
      } else {
        this.checkboxTarget.parentElement.parentElement.classList.add('hidden');
      }
    }
  }

  _loadData(callback) {
    if (this.ajaxRequest) {
      this.ajaxRequest.abort(); // Abort previous ajax request.
    }

    ajax({
      url: this._url(),
      type: "GET",
      dataType: "script",
      beforeSend: (xhr) => {
        this.ajaxRequest = xhr;
        return true;
      },
      success: () => {
        if (callback) callback();
      }
    })
  }

  _url() {
    if (Object.values(this.params).filter(value => value).length > 0) {
      const queryString = this._prepareQueryString();

      return this.url + queryString;
    } else {
      return this.url;
    }
  }

  _prepareQueryString() {
    const params = [];

    for (let param in this.params) {
      const value = this.params[param];

      if (value && value.length) {
        params.push(`${param}=${value}`)
      }
    }

    const queryString = params.join("&");

    if (this.url.match(/\?/)) {
      return "&" + queryString;
    } else {
      return "?" + queryString;
    }
  }

  toggleCloseIcon() {
    if (this.hasCloseIconTarget) {
      if (this.inputTarget.value === '') {
        this.closeIconTarget.classList.add('hidden');
      } else {
        this.closeIconTarget.classList.remove('hidden');
      }
    }
  }
}
