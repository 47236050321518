import { Controller } from "stimulus";

export default class extends Controller {

  static targets = [
    'hiddenOffset',
    'hiddenDestroy',
    'calendarInput',
    'calendarButton',
    'daysInput',
    'defaultCalendarInputValue'
  ];

  static values = {
    startDate: String
  }

  syncCalendar() {
    setTimeout(() => {
      let newOffset = this.daysInputTarget.value

      if (newOffset === '' || newOffset === undefined) {
        this._clearCalendar();
        this._disableDestroy(true);
        return;
      }

      if (this._hasCalendarTargets()) {
        let newDueDate = this._calculateNewDueDate(newOffset);

        this._setCalendarButtonValue(this._formatCalendarButtonDate(newDueDate));
        this._setCalendarInputValue(this._formatCalendarInputDate(newDueDate));
      }

      this._disableDestroy(false);
      this._setHddenOffsetValue(newOffset);
    }, 200);
  }

  syncOffset() {
    if (this.calendarInputTarget.value === '') {
      this._setDaysInputValue(0);
      this._disableDestroy(true);
      return;
    }

    let newOffset = this._calculateOffset();

    this._setDaysInputValue(newOffset);

    this._disableDestroy(false);
    this._setHddenOffsetValue(newOffset);
  }

  clearDates(event) {
    event.preventDefault();

    this._setDaysInputValue('');
    this._clearCalendar();
    this._disableDestroy(true);
  }

  _clearCalendar() {
    if (this._hasCalendarTargets()) {
      this._setCalendarButtonValue(this.defaultCalendarInputValueTarget.innerText);
      this._setCalendarInputValue('');
    }
  }

  _calculateNewDueDate(offset) {
    let programStartDate = new Date(this.startDateValue);

    return new Date(
      programStartDate.setDate(programStartDate.getDate() + parseInt(offset))
    );
  }

  _calculateOffset() {
    let programStartDate = new Date(this.startDateValue);

    let newDueDate = new Date(this.calendarInputTarget.value);
    let oneDay = 1000 * 3600 * 24;
    let diffTime = newDueDate.getTime() - programStartDate.getTime();
    let diffDays = diffTime / oneDay;

    return diffDays;
  }

  _formatCalendarButtonDate(date) {
    // return format "30 Jul 2021"
    let utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
    return `${utcDate.getDate()} ${utcDate.toLocaleString('en-GB', { month: 'short', year: 'numeric' })}`;
  }

  _formatCalendarInputDate(date) {
    // return format "2021-07-30"
    return date.toISOString(true).slice(0,10);
  }

  _setHddenOffsetValue(value) {
    this.hiddenOffsetTarget.value = value + ' days';
  }

  _hasCalendarTargets() {
    return (this.hasCalendarInputTarget || this.hasCalendarButtonTarget)
  }

  _setCalendarButtonValue(value) {
    if (this.hasCalendarButtonTarget) {
      this.calendarButtonTarget
        .querySelector('.admin-button-text')
        .innerHTML = value;
    }
  }

  _setCalendarInputValue(value) {
    if (this.hasCalendarInputTarget) {
      this.calendarInputTarget.value = value;
    }
  }

  _setDaysInputValue(value) {
    let formatedValue = value ? String(value).padStart(2, 0) : '';
    this.daysInputTarget.value = formatedValue;
  }

  _disableDestroy(shouldDestroy) {
    this.hiddenDestroyTarget.disabled = !shouldDestroy;
  }
}
