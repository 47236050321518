import { ajax } from '@rails/ujs';
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['content'];

  loadContent(event) {
    if (this._contentPreviouslyLoaded()) return;

    this._loadData();
  };

  _loadData() {
    if (this.ajaxRequest) {
      this.ajaxRequest.abort(); // Abort previous ajax request.
    }

    ajax({
      url: this._url(),
      type: 'GET',
      dataType: 'script',
      beforeSend: (xhr) => {
        this.ajaxRequest = xhr;
        return true;
      }
    });
  };

  _url() {
    return this.contentTarget.dataset.contentLoaderUrlValue;
  };

  _contentPreviouslyLoaded() {
    return this.contentTarget.dataset.contentLoaderLoaded === 'true';
  };
}
